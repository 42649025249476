

.header {
    position: fixed;
    z-index: 11;
    // width: 100%;
    // height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.black{
    position: fixed;
    // background-color: rgba($color: #000000, $alpha: 0.4);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header > * {

      z-index: 12;
  }

.bg-info {
position: absolute;
border-radius: 0.3rem;
max-width: 20rem;
height: 10rem;
}

/* start info */
.header-info {
    position: relative;
    display: flex;
    flex-direction: column;
}

.info-limit-bet {
    height: 100%;
    width: max-content;
    display: flex;
    flex-direction: column;
}
.info-period,
.info-limit-bet {
    border-radius: 0.75rem;
    color: #fff;
}

.info-limit-bet-items {
    display: flex;
    align-items: center;
}

.info-limit-text,
.info-period-text {
    font-size: 1.5rem;
    font-weight: 500;
    white-space: nowrap;
    width: 100%;
    padding: 0.5rem 1.5rem;
}
/* end info */

// landscape
.landscape {

    .header{
        top: 7.5rem;
        right: 3rem;
    }

    .header-info {
        padding-top: 0.5rem;
     }

    .info-limit-bet {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 2rem;
    }

    .info-period {
        margin-bottom: 0.25rem;
        height: 100%;
        display: flex;
    }
}


// portrait
.portrait {
    .header {
        top: 0rem;
        right: 25rem;
        // height: 100rem;
        // background-color: rgba($color: #000000, $alpha: 0.4);
    
    }
    .black{
        height: 100rem;
        width: 100rem;
        left: -30rem;
    }
    .header-info {
        padding-top: 1rem;
    }

    .info-limit-bet {
      
    }

    .info-period {
        margin-bottom: 0.5rem;
        
    }
    .info-limit-text{
        font-size: 1.5rem;
        font-weight: 500;
    }
    .info-limit-text,
    .info-period-text {
        padding: 0.5rem 2rem;

    }
}
