.landscape {
  .history {
    position: absolute;
    top: 5rem;
    left: 4.5rem;
    display: flex;
    height: 50rem;
    flex-direction: column;
    justify-content: center;
    .history-list::-webkit-scrollbar{
      display: none;
  }
    .history-list {
      width: max-content;
      border: 0.5rem solid #6033e1;
      background-color: #6033e1;
      border-radius: 0.3rem;
      max-height: 31rem;
      overflow-y: hidden;
      margin-left: 1rem;
      justify-content: flex-end;
      display: flex;
      flex-direction: column-reverse;
            .list-item {
        width: 10rem;
        min-height: 6rem;
        filter: brightness(0.9);
        filter: contrast(0.9);
        filter: saturate(0.8);
        border-radius: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.25rem;
        .item-text {
          color: white;
          font-size: 2.5rem;
          font-weight: 500;
          text-shadow: black 0 -0.15rem;
          -webkit-text-stroke: 0.5rem rgba($color: #fff, $alpha: 0.2);
        }
      }
      .list-item:last-child {
        margin-top: 0rem;
      }
    }
    .history-view_all {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .history-view_all-bg {
        margin-top: 1rem;
      }
      .view_all-text {
        color: white;
        margin-top: -2.5rem;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}

.portrait {
  .history {
    position: absolute;
    top: 18rem;
    left: 3.5rem;
    width: 63rem;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    .history-list::-webkit-scrollbar{
      display: none;
  }
    .history-list {
      max-width: 61rem;
      border: 0.7rem solid #6033e1;
      background-color: #6033e1;
      border-radius: 0.3rem;
      flex-direction: row;
      display: flex;
      overflow-x: hidden;

      .list-item {
        min-width: 10rem;
        height: 7rem;
        background-color: #b16c00;
        border-radius: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.25rem;
        .item-text {
          color: white;
          font-size: 2.5rem;
          font-weight: 500;
          text-shadow: black 0 -0.15rem;
          -webkit-text-stroke: 0.5rem rgba($color: #fff, $alpha: 0.2);
        }
      }
      .list-item:first-child {
        margin-top: 0rem;
      }
    }
    .history-view_all {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .history-view_all-bg {
        margin-top: 1rem;
      }
      .view_all-text {
        color: white;
        margin-top: -2.5rem;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}
