.landscape {
  .hole-wrapper {
    position: absolute;
    left: 25rem;
    top: 53.4rem;
    .hole-container {
      height: 5rem;
      background-color: var(--hole-container-color);
      z-index: 2;
      height: 2rem;
      z-index: 3;
      display: flex;
      align-items: center;

      .hole-bg-image {
        opacity: 0;
      }
      .hole-list {
        position: relative;

        height: 2rem;
        z-index: 3;
        display: flex;
        align-items: center;
        perspective: 500px;
        -webkit-perspective: 500px;
        background-color: var(--hole-container-color);
        justify-content: space-around;

        .list-item {
          display: flex;
          justify-content: center;
          align-content: center;
          overflow: visible;
          width: 3rem;
          min-height: 1.5rem;
          transform: rotateX(30deg);
          transform-origin: 50% 50% 0px;
          padding-inline:0.2%;
        }
        .item-hole,
        .list-item{
          width: 3.5rem;
          height: 1.5rem;
        }

        .item-hole {

          .hole {
            height: 100%;
            width: 100%;
            box-shadow: inset 0 0 0.5rem #000;
            filter: brightness(70%);
          }
          
        }
      }
      .light-list {
        // padding: 0rem 0.1rem;
        position: absolute;

        height: 2rem;
        z-index: 2;
        display: flex;
        align-items: center;
        .list-item {
          display: flex;
          justify-content: center;
          align-content: center;
          overflow: visible;
          width: 3rem;
          height: 1.5rem;
          .item-light {
            //   margin-top: -1.5rem;
            position: absolute;
            width: 1.5rem;
            height: 1rem;
            
              // filter: blur(0.2);
          }
        }
      }
    }
    .label-list {
      // padding: 0rem 0.9rem;
      position: relative;
      height: 2rem;
      z-index: 5;
      display: flex;
      align-items: center;
      perspective: 500px;
      -webkit-perspective: 500px;
      margin: 1.25rem 0;
      justify-content: space-around;
            // filter: brightness(100%);

      .list-item {
        padding: 0.2rem 0rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 2.5rem;
        box-shadow: inset 0 0 0.5rem #000;
        .refer {
          position: absolute;
          // rotate: 90deg;
          margin-top: -5.5rem;
        }
        .label {
          transform: rotateX(0deg);
          transform-origin: 50% 50% 0px;
          text-align: center;
            font-weight: 500;
            font-size: 0.9rem;
            min-width: 2.5rem;
        rotate: -90deg;
        color:#000;
        // -webkit-text-stroke: 0.05rem #000;
        text-shadow:  0rem 0px 3px rgba($color: #fff, $alpha: 0.2), 0rem 0px 3px rgba($color: #fff, $alpha: 0.2),  0px 0rem 3px rgba($color: #fff, $alpha: 0.2), 0px 0rem 3px rgba($color: #fff, $alpha: 0.2);
        }
      }
    }
  }
}

.portrait {


  .hole-wrapper {
    position: absolute;
    // left: 27rem;
    width: 100%;
    top: 96rem;

    .hole-container {
      height: 5rem;
      z-index: 2;
      width: 100%;
      height: 2rem;
      z-index: 3;
      display: flex;
      align-items: center;
      
      .hole-bg-image {
        opacity: 0;
      }
      .hole-list {
        justify-content: space-around;
        position: relative;
        height: 3rem;
        z-index: 3;
        display: flex;
        align-items: center;
        perspective: 500px;
        -webkit-perspective: 500px;
        background-color: var(--hole-container-color);
        .list-item {
          display: flex;
          justify-content: center;
          align-content: center;
          overflow: visible;
          width: 3rem;
          height: 3rem;
          transform: rotateX(45deg);
          transform-origin: 50% 50% 0px;
        }
        .item-hole {
          width: 3.87rem;
          height: 3rem;
          .hole {
            height: 100%;
            width: 100%;
            box-shadow: inset 0 0 0.5rem #000;
            filter: brightness(70%);
          }
          
        }
      }
      .light-list {
        position: absolute;
        height: 2rem;
        z-index: 2;
        display: flex;
        align-items: center;
        .list-item {
          display: flex;
          justify-content: center;
          align-content: center;
          overflow: visible;
          width: 3rem;
          height: 2.5rem;
          .item-light {
            //   margin-top: -1.5rem;
            position: absolute;
            width: 1.5rem;
            height: 1rem;
            
            //   filter: blur(0.2);
          }
        }
      }
    }
    
    .label-list {
      position: relative;
      height: 3rem;
      z-index: 5;
      display: flex;
      align-items: center;
      perspective: 500px;
      -webkit-perspective: 500px;
      margin-top: 1.7rem;
      justify-content: space-around;
      font-size: 1.5rem;
      .list-item {
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 4rem;
        transform: rotateX(0deg);
        transform-origin: 50% 50% 0px;
        box-shadow: inset 0 0 0.5rem #000;
        .refer {
          position: absolute;
          rotate: 0deg;
          margin-top: -11.5rem;
          z-index: 6;
        }
        .label {
          rotate: -90deg;
            font-weight: 500;
        // text-shadow:  0rem 0px 2px black, 0rem 0px 2px black,  0px 0rem 2px black, 0px 0rem 2px black;
        color: #000;

        }
      }
    }
  }
}
