.bet-wrapper {

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    -webkit-appearance: none;
  }
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bet-form-ball {
  .bet-form-tool-list {
    display: none !important;
  }

  .dropdown-select {
    display: none !important;
  }
}


.bet-form-tool-list.disabled {
  opacity: 30%;
}

.landscape {
  .bet-wrapper {
    position: fixed;
    top: 18rem;
    right: -3rem;

    .bet {
      position: relative;
      color: white;
      height: 42rem;

      .disable {
        position: absolute;
        height: 75%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0);
        z-index: 2;
      }

      .bet-form {
        height: max-content;
        position: absolute;
        width: max-content;
        top: 3.25rem;
        margin-left: 2.5rem;
        display: flex;
        flex-direction: column;
        height: 26.5rem;
        justify-content: space-between;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        .form-group {
          display: flex;
          flex-direction: column;
          width: 22.5rem;
          // margin-top: 3rem 0;
          position: relative;

          label,
          input {
            margin-left: 0.5rem;
          }

          .dropdown-select {
            width: 100%;
            height: max-content;
            top: 6rem;
            position: absolute;
            background-color: #26345f;
            border-radius: 0.75rem;
            z-index: 1;
            font-weight: 500;
            font-size: 1.5rem;
            overflow: hidden;
            box-shadow: inset 0 0 0.3rem #000;

            .select-item {
              width: 100%;
              height: 3rem;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              &>* {
                padding-left: 1rem;
              }
            }

            .select-item:hover {
              background-color: #43a9ff;
            }
          }

          .bet-form-tool-list {
            position: absolute;
            right: 0.2rem;
            top: 2.9rem;
            height: 2rem;
            display: flex;
            flex-direction: row;
            width: max-content;

            .tool-item {
              margin: 0 -0.15rem;

              span {
                display: block;
                font-weight: 600;
                position: absolute;
                padding: 0.6rem 0.75rem;
                margin: 0.125rem 0.125rem;
                // background-color: rgba($color: #000000, $alpha: 0.5);
              }

              .tool-bg-dropdown {
                margin: 0.25rem 0.15rem;
              }
            }
          }

          input:focus {
            appearance: unset;
          }

          input {
            font-weight: 500;
            font-size: 1.75rem;
            color: white;
            width: 16rem;
            margin-top: 0.75rem;
            background-color: transparent;
            border: none;
            height: 3rem;
          }

          label {
            font-weight: 500;
            font-size: 1.5rem;
            height: 2rem;
          }
        }

        .form-group.bet-form-bet .bet-form-tool-list {
          top: 3.1rem;
        }
      }

      // .bet-button:active {
      //   margin-top: -11.5rem;
      // }

      .bet-button.center {
        margin-left: 9rem;
      }

      .bet-button.auto {
        margin-left: 3rem;
      }

      .bet-button.non-auto {
        margin-left: 15rem;
        // margin-top: 10rem;
      }

      .bet-button {
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: -10rem;
        margin-left: -3rem;
        cursor: pointer;

        .bet-button-bg {
          margin-top: 1rem;
          width: 10rem;

        }

        .button-text {
          color: black;
          margin-top: -3.8rem;
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
  }
}

.portrait {
  .bet-wrapper {
    position: fixed;
    bottom: 15rem;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 5;

    .bet {
      position: relative;
      color: white;
      height: max-content;

      .disable {
        position: absolute;
        height: 65%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0);
        z-index: 2;
      }

      .bet-bg {
        //
      }

      .bet-form {
        height: max-content;
        position: absolute;
        width: max-content;
        top: 2.25rem;
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;
        height: 26.5rem;
        justify-content: space-between;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        .form-group.bet-form-bet {
          position: absolute;
          top: -0.1rem;

          // left: 0.5rem;
          .bet-form-tool-list {
            right: 1.1rem;
          }

          input {
            margin-top: 0.75rem;
            font-size: 2rem;
            width: 22rem;
          }
        }

        .form-group.bet-form-ball {
          position: absolute;
          top: 9rem;
          // left: 0.5rem;
          margin-top: 0.3rem;
        }

        .form-group.bet-form-risk {
          position: absolute;
          left: 32rem;
          top: -0.05rem;
        }

        .form-group.bet-form-rows {
          position: absolute;
          left: 32rem;
          top: 8.75rem;
          margin-top: 0.7rem;
        }

        .form-group {
          display: flex;
          flex-direction: column;
          width: 30.8rem;
          // margin-top: 3rem 0;
          position: relative;

          label,
          input {
            margin-left: 1.5rem;
          }

          .dropdown-select {
            width: 98%;
            height: max-content;
            top: 6.2rem;
            position: absolute;
            background-color: #26345f;
            border-radius: 0.75rem;
            z-index: 1;
            font-weight: 500;
            font-size: 1.5rem;
            overflow: hidden;
            box-shadow: inset 0 0 0.3rem #000;
            margin-left: 0.5rem;

            .select-item {
              width: 100%;
              height: 3rem;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              &>* {
                padding-left: 1rem;
              }
            }

            .select-item:hover {
              background-color: #43a9ff;
            }
          }

          .bet-form-tool-list {
            position: absolute;
            right: 0.6rem;
            top: 3rem;
            height: 2rem;
            display: flex;
            flex-direction: row;
            width: max-content;
            transform: scale(1.3);

            .tool-item {
              margin: 0 -0.15rem;

              span {
                display: block;
                font-weight: 600;
                position: absolute;
                padding: 0.6rem 0.75rem;
                margin: 0.125rem 0.125rem;
                // background-color: rgba($color: #000000, $alpha: 0.5);
              }

              .tool-bg-dropdown {
                margin: 0rem 0.15rem;
                transform: scale(1.15);
              }
            }
          }

          input:focus {
            appearance: unset;
          }

          input {
            font-weight: 500;
            font-size: 1.75rem;
            color: white;
            width: 16rem;
            margin-top: 0.55rem;
            background-color: transparent;
            border: none;
            height: 3rem;
            margin-left: 1.5rem;
            padding-left: 0;
          }

          label {
            font-weight: 500;
            font-size: 1.5rem;
            height: 2rem;
          }
        }
      }

      // .bet-button:active {
      //   margin-top: -11.5rem;
      // }
      .bet-button.auto {
        margin-left: 11.5rem;
      }

      .bet-button.center {
        margin-left: 23rem;
      }

      .bet-button.non-auto {
        margin-left: 34rem;
        // margin-top: 10rem;
      }

      .bet-button {

        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: -12rem;
        margin-left: -3rem;
        width: fit-content;
        cursor: pointer;

        .bet-button-bg {
          margin-top: 1rem;
          width: 20rem;
        }

        .button-text {
          color: black;
          margin-top: -7rem;
          font-size: 3rem;
          font-weight: 600;
        }
      }
    }
  }
}

// @media (max-width: 770px) {
//   .landscape .bet .bet-form .form-group input {
//     margin-top: unset;
//   }
// }