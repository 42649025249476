.setting {
  z-index: 12;
}

.landscape {
  .setting {
    position: absolute;
    top: 2rem;
    right: 2rem;
    .setting-bg-range {
      position: absolute;
        top: -0.4rem;
    }
    .volume-range {
      // position: relative;
      // top: -2rem;
      // left: 0.6rem;
      z-index: 7;
      width: 100%;
    }
    .setting-volume {
      color: black;
      position: absolute;
      left: 7rem;
      top: 5rem;
      z-index: 15;
    }
    .setting-list {
      margin-top: -4rem;
      // display: flex;
      // width: 16rem;
      // background-color: red;
      display: flex;
      justify-content: space-around;
      // gap: 5rem;


      .list-item {
        .item-text {
        }
      }
      .list-item:first-child {
        margin-top: 0rem;
      }
    }
    .history-view_all {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .history-view_all-bg {
        margin-top: 1rem;
      }
      .view_all-text {
        color: white;
        margin-top: -2.75rem;
        font-size: 2rem;
      }
    }
  }
}

.portrait {
  .setting {
    position: absolute;
    top: 2rem;
    right: 6rem;
    transform: scale(1.5);

    .volume-range {
      position: relative;
      z-index: 7;
      width: 100%;
    }
    .setting-volume {
      position: absolute;
      left: 4.5rem;
      top: 5rem;
      z-index: 6;
      color: black;
    }
    .setting-bg-range {
      position: absolute;
        top: -0.4rem;
        left: 4rem;
    }
    .setting-list {
      margin-top: -4rem;
      // display: flex;
      // width: 16rem;
      // background-color: red;
      display: flex;
      justify-content: space-around;
      // gap: 5rem;
      .list-item {
        .item-text {
        }
      }
      .list-item:first-child {
        margin-top: 0rem;
      }
    }
    .history-view_all {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .history-view_all-bg {
        margin-top: 1rem;
      }
      .view_all-text {
        color: white;
        margin-top: -2.75rem;
        font-size: 2rem;
      }
    }
  }
}
