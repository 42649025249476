$amount: #6fc847;
@media only screen and (max-width: 1189px) {


.landscape .footer-total-win .footer-total-win-content {
    font-size: 1.5vw;
  }
}
@media only screen and (max-width: 1150px) {


    .landscape .footer-total-win .footer-total-win-content {
    font-size: 1.5vw;
  }

  .landscape {

    .footer-total-amount .footer-total-amount-content {
      font-size: 1.5vw;
    }
  }
  .landscape .footer-info-user-value,
  .landscape .footer-info-balance-value {
    font-size: 1.25vw !important;
  }
}
@media only screen and (max-width: 800px) {
  .landscape {
    .footer-total-win .footer-total-win-content {
      font-size: 1.5vw;
    }

    .footer-total-amount .footer-total-amount-content {
      font-size: 1vw;
    }
  }
  .landscape .footer-info-user-value,
  .landscape .footer-info-balance-value {
    font-size: 1vw !important;
  }
}
// todo: transform scale
.landscape {
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    // scale: 0.5;
    height: 10vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    color: white;
  }
  $amount: #6fc847;

  // .footer {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;

  //     // height: 155px;
  // }

  .footer-total-win {
    // position: absolute;
    // left: 50%;

    // transform: translate(-50%, 0);

    width: auto;
    height: 100%;
  }

  .footer-total-win-bg {
    // position: absolute;
    // inset: 0;
    height: 100%;
    width: 25vw;
    margin-bottom: -9vh;
  }

  .footer-total-amount,
  .footer-info {
    // position: absolute;
    width: 30%;
  }

  .footer-total-win-content,
  .footer-total-amount-content {
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .footer-total-win-content {
    // position: absolute;
    width: 100%;
    font-size: 1.5vw;
    // top: -0.5rem;
    margin-top: 0;
    height: auto;
  }

  .footer-total-amount-content {
    // position: absolute;
    width: 100%;
    height: 5vh;
    font-size: 2.5vh;
    margin-top: 3vh;
    background-color: black;
    border-radius: 3vh;
  }

  .footer-info {
    margin-top: 3vh;
    background-color: black;
    border-radius: 3vh;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
  }

  .footer-info-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;

  }

  .footer-info-icon {
    height: 2.5vh;
    width: auto;
  }

  .footer-info-user-value,
  .footer-info-balance-value {
    font-size: 2vh;

  }

  .footer-total-win-value,
  .footer-total-amount-value,
  .footer-info-balance-value {
    color: $amount;
    font-weight: 600;
  }
}

.portrait {
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    // height: 155px;
  }

  .footer-total-win {
    position: absolute;
    left: 50%;

    transform: translate(-50%, 0);

    width: 30rem;
    height: 15.5rem;
  }

  .footer-total-win-bg {
    position: absolute;
    inset: 0;
  }

  .footer-total-amount,
  .footer-info {
    position: absolute;
  }

  .footer-total-win-content,
  .footer-total-amount-content {
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .footer-total-win-content {
    position: absolute;
    width: 100%;
    font-size: 3.8rem;
    top: -0.5rem;
  }

  .footer-total-amount-content {
    position: absolute;
    width: 100%;
    height: 3rem;
    font-size: 2rem;
  }

  .footer-info {
    background-color: black;

    display: flex;
    align-items: center;
    z-index: 10;
  }

  .footer-info-content {
    display: flex;
    align-items: center;
  }

  .footer-info-icon {
    height: 30px;
    width: auto;
  }

  .footer-info-user-value,
  .footer-info-balance-value {
    font-size: 1.6rem;
  }

  .footer-total-win-value,
  .footer-total-amount-value,
  .footer-info-balance-value {
    color: $amount;
    font-weight: 600;
  }
  // $winBottom: 2rem;
  // $amountBottom: calc($winBottom+);
  .footer-total-win {
    bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-total-win-bg {
    position: unset;
    inset: unset;
  }
  .footer-total-win-content {
    padding-top: 2rem;
    height: 9rem;
    font-size: 2.5rem;
  }

  .footer-total-amount {
    left: 50%;
    bottom: 2.5rem;
    transform: translate(-50%, 0);

    width: 60rem;
    height: 6rem;
  }

  .footer-info {
    left: 0;
    bottom: 0;

    width: 100%;
    height: 5rem;
    padding-right: 3rem;

    justify-content: flex-end;
    gap: 4rem;
  }

  .footer-info-content {
    gap: 1rem;
  }
}
