.td-date,
.td-betperball {
  width: 20%;
  text-align: center;
}
.td-betperball{
  text-align: left;

}
.td-period {
  text-align: center;
  width: 10%;
}

.td-ball {
  width: 10%;
  text-align: center;

}

.td-totalbet,.td-multiplier {
  width: 25%;
  text-align: center;
  padding-left: 0.5rem;
}

.td-payout {
  width: 25%;
  text-align: left;
  padding-left: 0.5rem;
}
.td-icon {
  width: 10%;
  font-size: 3rem;
  color: rgb(58, 33, 123);
}

.history {
  font-weight: 600;

}

.table-head {
  color: white;

}

.table-body {
  color: #a287e5;
  box-shadow: inset 0 0.1rem 0.5rem rgba($color: #000000, $alpha: 0.5), 0 0.1rem 0.5rem rgba($color: #000000, $alpha: 0.5);

  tr:nth-child(odd){
    height: 5rem;
  }
  
}

.retable {
  display: table;
  // margin-top: -1px;

  width: 98%;
  table {
    color: white;
    tr,td{
      // height: 5rem;
      vertical-align: middle;
    }
   
    tbody{
      tr {
        border-bottom: 1px solid #a287e5;
      }
    }
  }
  background-color: rgb(93, 49, 184);
  border-radius: 1.5rem;
  overflow: hidden;
  .head-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
td{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
  }
}

.landscape {
  .history {

    .table-container {
      position: fixed;
      top: 0;
      left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 6;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .black{
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.6);
        z-index: 6;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      *{
        z-index: 7;
      }
      .table-bg {
        margin-top: -3rem;
      }
      .table-title {
        position: absolute;
        top: 10rem;
        display: flex;
        width: 54rem;
        // background-color: black;
        justify-content: space-between;
        .title {
          font-size: 1.6rem;
        }
      }
      .table {
        position: absolute;
        top: 15rem;
        font-size: 1.1rem;
        .table-head {
          width: 54rem;
          height: 4rem;
          overflow-x: visible;
          display: flex;
          justify-content: center;
          .table-head-scroll {
            width: 50rem;
            background-repeat: no-repeat;
            height: 100%;
            overflow-x: scroll;

            .head-list {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              .list-item {
                text-align: center;
              }
            }
          }
        }
        .table-body::-webkit-scrollbar, .table-head-scroll::-webkit-scrollbar{
          display: none;
      }
        .table-body {
          margin-top: 2rem;
          margin-left: 0.5rem;
          background-color: #fff;
          width: 53rem;
          height: 33rem;
          border-radius: 1rem;
          overflow-y: scroll;
          overflow-x: hidden;
          table {
            width: 100%;
            text-align: center;
            border-collapse: collapse;
            
          }
        }
      }
    }
    .slider-x {
      display: none;
    }
    .slider-y {
      position: absolute;
      transform: rotate(90deg);
      right: -17.1rem;
      top: 20rem;
      width: 33rem;
    }
    .rangeslider {
      margin: 0 auto;
      padding: 20px 0;
      width: 100%;
    }
    input[type="range"] {
      background: #2b126a;
      border-bottom: 1px solid #4b4b4b;
      border-top: 1px solid #060606;
      // height: 15px;
      width: 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 12px;
    }
    .rangeslider,
    input::-webkit-slider-thumb {
      background: #a287e5;
      border: 1px solid #1a1a1a;
      cursor: pointer;
      height: 20px;
      text-indent: -9999px;
      width: 180px;
      box-shadow: 0 3px 6px rgba(0,0,0,0.3), 0 1px 0 rgba(255,255,255,0.2) inset;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 10px;
      // margin-bottom: 0.1rem;
    }
    input[type="range"]:focus {
      outline: none;
    }
  }
}




.portrait {
  
  .history {
    .table-container {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 6;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(1.15);

      .black{
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.6);
        z-index: 6;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      *{
        z-index: 7;
      }
      .table-bg {
        margin-top: -3rem;
      }
      .table-title {
        position: absolute;
        top: 53rem;
        display: flex;
        width: 54rem;
        // background-color: black;
        justify-content: space-between;
        .title {
          font-size: 1.6rem;
        }
      }
      .table {
        position: absolute;
        top: 58rem;
        font-size: 1.1rem;
        .table-head {
          width: 54rem;
          height: 4rem;
          color: #baff5d;
          overflow-x: visible;
          display: flex;
          justify-content: center;
          .table-head-scroll {
            width: 50rem;
            background-repeat: no-repeat;
            height: 100%;
            overflow-x: scroll;

            .head-list {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              .list-item {
                text-align: center;
              }
            }
          }
        }
        .table-body::-webkit-scrollbar, .table-head-scroll::-webkit-scrollbar{
          display: none;
      }
        .table-body {
          margin-top: 1rem;
          margin-left: 0.5rem;
          background-color: #fff;
          width: 53rem;
          height: 33rem;
          border-radius: 1rem;
          overflow-y: scroll;
          overflow-x: scroll;
          & > table {
            width: 100%;
            text-align: center;
            border-collapse: collapse;
                   .retable {

              table {
                width:100%;
            
              }
            }
          }
        }
      }
    }
    .slider-y {
      position: absolute;
      transform: rotate(90deg);
      right: -17.1rem;
      top: 20rem;
      width: 33rem;
    }
    .rangeslider {
      margin: 0 auto;
      padding: 20px 0;
      width: 100%;
    }
    input[type="range"] {
      background: #2b126a;
      border-bottom: 1px solid #4b4b4b;
      border-top: 1px solid #060606;
      // height: 15px;
      width: 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 12px;
    }
    .rangeslider,
    input::-webkit-slider-thumb {
      background: #a287e5;
      border: 1px solid #1a1a1a;
      cursor: pointer;
      height: 20px;
      text-indent: -9999px;
      width: 180px;
      box-shadow: 0 3px 6px rgba(0,0,0,0.3), 0 1px 0 rgba(255,255,255,0.2) inset;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 10px;
      // margin-bottom: 0.1rem;
    }
    input[type="range"]:focus {
      outline: none;
    }
  }
}
