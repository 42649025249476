.landscape {
  .percentage {
    position: absolute;
    left: 27.5rem;
    bottom: 13rem;
    z-index: 6;
    .percentage-bg {
    }
    .percentage-form {
      position: absolute;
      width: max-content;
      display: flex;
      margin-top: -7rem;
      margin-left: 1.5rem;
      .form-group {
        //   background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        flex-direction: column;
        width: 24rem;
        margin-bottom: 2rem;
        margin-right: 2.5rem;
        position: relative;
        label,
        input {
          margin-left: 0.5rem;
          height: 2.5rem;
        }
        input:focus {
          appearance: unset;
        }
        input {
          font-weight: 500;
          font-size: 1.75rem;
          color: white;
          margin-top: 0.15rem;
          background-color: transparent;
          border: none;
        }
        label {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.portrait {
  .percentage {
    position: absolute;
    bottom: 58rem;
    left: 8rem;
    z-index: 6;
    transform: scale(1.25);

    .percentage-bg {
    }
    .percentage-form {
      position: absolute;
      width: max-content;
      display: flex;
      margin-top: -7rem;
      margin-left: 1.5rem;
      .form-group {
        //   background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        flex-direction: column;
        width: 24rem;
        margin-bottom: 2rem;
        margin-right: 2.5rem;
        position: relative;
        label,
        input {
          margin-left: 0.5rem;
          height: 2.5rem;
        }
        input:focus {
          appearance: unset;
        }
        label {
          font-size: 1.5rem;
        }
        input {
          font-weight: 500;
          font-size: 1.75rem;
          color: white;
          margin-top: 0.15rem;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
