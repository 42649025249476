.app {
  position: relative;
  width: var(--width-screen);
  height: var(--height-screen);
  background-color: black;

}

.game-area {
  width: var(--width-game);
  height: var(--height-game);

  position: absolute;
  top: 50%;
  left: 50%;

  // background-color: white;
  // background-color: black;

  color: white;

  transform: var(--scale-game) translate(-50%, -50%) translateZ(0);
  transform-origin: top left;
  overflow: hidden;

  z-index: 100;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.loading-screen {
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  font-size: 2rem;
  background-color: #160335;

  // background-color: red;
}

#myProgress {
  overflow: hidden;
  background-color: rgb(59, 57, 57);
}

#myBar {
  width: 0;
  background-color: #2BD6C5;
}

.landscape .loading-screen {
  img:first-child {
    width: 35%;
  }
  img {
    width: 10%;
  }

  #myProgress {
    width: 40%;
    border-radius: 0.5rem;
  }

  #myBar {
    height: 0.5rem;
  }
}

.portrait .loading-screen {
  img:first-child {
    width: 40%;
  }

  img {
    width: 15%;
  }


  #myProgress {
    width: 40%;
    border-radius: 0.5rem;
  }

  #myBar {
    height: 0.5rem;
  }
}

.error-screen {
  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2rem;
  color: white;
}