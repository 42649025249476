.landscape {
  .out {
    position: fixed;
    top: 0;
    left: 0;
    // background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 7;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .black{
      position: fixed;
      background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 6;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    *{
      z-index: 7;
    }
    .out-bg {
      margin-top: -3rem;
    }
    .out-title {
      display: flex;
      // background-color: #000000;
      justify-content: space-between;
      position: absolute;
      top: 9.5rem;
      width: 40rem;

      .title-bet {
        font-size: 1.8rem;
        font-weight: 500;
        margin-left: 0.5rem;

        span {
          margin-left: 0.5rem;
        }
      }
    }
    .count {
      position: absolute;
      top: 14rem;
      font-size: 2rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.5rem;
      // margin-left: 3rem;
      .tool {
        margin-left: 1rem;
        display: flex;
        height: 100%;
        .tool-icon {
          margin-left: 0.5rem;
        }
      }
    }

    .placed {
      position: absolute;
      top: 17rem;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
        margin-bottom: 1rem;
      }
    }

    .bmp {
      position: absolute;
      top: 23rem;
      font-size: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .bmp-container {
        display: flex;
        flex-direction: row;
        margin-top: -7.3rem;
        width: 40rem;
        height: 7rem;
        justify-content: center;
        align-items: center;
        div {
          height: 4.2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
        }
        .bmp-bet {
          width: 14rem;
          // background-color: #000000;
        }
        .bmp-multiplier {
          width: 12rem;
        }
        .bmp-payout {
          width: 14rem;
        }
      }
    }
    .multiplier {
      position: absolute;
      top: 31rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .multiplier-label {
        margin-top: -3.25rem;
        font-weight: 500;
        font-size: 2rem;
        z-index: 8;
      }
      .multiplier-bg {
        width: 8rem;
        height: 4.2rem;
        display: flex;
        border-radius: 0.5rem;
        filter: contrast(0.9);
        box-shadow: 0 0.2rem 0.2rem 0.2rem rgba(0,0,0,0.1), inset 0rem 0.3rem 0.5rem 0rem rgba($color: #fff, $alpha: 0.3), inset 0rem -0.3rem 0.3rem 0rem rgba($color: #fff, $alpha: 0.1);
        
      }
    }
    .out-form {
      display: flex;
      // background-color: #000000;
      position: fixed;
      top: 35.5rem;
      width: 40rem;
      flex-direction: column;
      .form-group {
        // background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        flex-direction: column;
        width: 36rem;
        margin-bottom: 2rem;
        margin-top: -0.4rem;
        margin-left: 1.5rem;
        position: absolute;
        label,
        input {
          margin-left: 0.5rem;
          margin-top: 0.4rem;
          font-size: 2rem;
        }
        input:focus {
          appearance: unset;
        }
        input {
          font-weight: 500;
          font-size: 1.75rem;
          color: white;
          margin-top: 0.65rem;
          background-color: transparent;
          border: none;
          //   height: 3rem;
        }
      }
      .play-button {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-top: -1.5rem;
        cursor: pointer;
        position: absolute;
        align-self: center;
        top: 14.5rem;
        .play-button-bg {
          //   margin-top: 1rem;
        }
        .button-text {
          color: black;
          margin-top: -2.8rem;
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
    }
  }
}


.portrait {
  .out {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.5);
    .black{
      position: fixed;
      background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 6;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    *{
      z-index: 7;
    }
    .out-bg {
      margin-top: -3rem;
    }
    .out-title {
      display: flex;
      // background-color: #000000;
      justify-content: space-between;
      position: absolute;
      top: 52.5rem;
      width: 40rem;

      .title-bet {
        font-size: 1.8rem;
        font-weight: 500;
        margin-left: 0.5rem;

        span {
          margin-left: 0.5rem;
        }
      }
    }
    .count {
      position: absolute;
      top: 57rem;
      font-size: 2rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.5rem;
      // margin-left: 3rem;
      .tool {
        margin-left: 1rem;
        display: flex;
        height: 100%;
        .tool-icon {
          margin-left: 0.5rem;
        }
      }
    }

    .placed {
      position: absolute;
      top: 60rem;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
        margin-bottom: 1rem;
      }
    }

    .bmp {
      position: absolute;
      top: 66rem;
      font-size: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .bmp-container {
        display: flex;
        flex-direction: row;
        margin-top: -7.3rem;
        width: 40rem;
        height: 7rem;
        justify-content: center;
        align-items: center;
        div {
          height: 4.2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
        }
        .bmp-bet {
          width: 14rem;
          // background-color: #000000;
        }
        .bmp-multiplier {
          width: 12rem;
        }
        .bmp-payout {
          width: 14rem;
        }
      }
    }
    .multiplier {
      position: absolute;
      top: 74rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .multiplier-label {
        margin-top: -3.25rem;
        font-weight: 500;
        font-size: 2rem;
        z-index: 8;
      }
      .multiplier-bg {
        width: 5rem;
        height: 4.2rem;
        display: flex;
        border-radius: 0.5rem;
        filter: contrast(0.9);
        box-shadow: 0 0.2rem 0.2rem 0.2rem rgba(0,0,0,0.1), inset 0rem 0.3rem 0.5rem 0rem rgba($color: #fff, $alpha: 0.3), inset 0rem -0.3rem 0.3rem 0rem rgba($color: #fff, $alpha: 0.1);
        
      }
    }
    .out-form {
      display: flex;
      // background-color: #000000;
      position: fixed;
      top: 78rem;
      width: 40rem;
      flex-direction: column;
      .form-group {
        // background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        flex-direction: column;
        width: 36rem;
        margin-bottom: 2rem;
        margin-top: -0.4rem;
        margin-left: 5vw;
        position: absolute;
        label,
        input {
          margin-left: 1vw;
          margin-top: 0.4rem;
          font-size: 2rem;
        }
        input:focus {
          appearance: unset;
        }
        input {
          font-weight: 400;
          font-size: 5vh;
          color: white;
          margin-top: -0.15rem;
          background-color: transparent;
          border: none;
          //   height: 3rem;
        }
      }
      .play-button {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // margin-top: -1.5rem;
        cursor: pointer;
        position: absolute;
        align-self: center;
        top: 14.5rem;
        .play-button-bg {
          //   margin-top: 1rem;
        }
        .button-text {
          color: black;
          margin-top: -2.8rem;
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
    }
  }
}
