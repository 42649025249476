:root {
    --hole-container-color: #bfd2ef;
}

.landscape {
    .game {
        .game-bg{
            position: absolute;
            left: 24rem;
            top: 2rem;
        }


.game-bg.big {
    left: 20rem;
    height: 80%;
    width: 58%;
}

.game-frame.big{
    left: 18.5rem;
    height: 13%;
    width: 60.5%;
}
        
        .game-frame{
            position: absolute;
            left: 23rem;
            bottom: 7.5rem;
            z-index: 5;
            
        }

        .border-frame {
            width: 60rem;
            height: 1rem;
            background: var(--hole-container-color);
            bottom: 11.7rem;
            margin-left: 1rem;
        }

        
    }
}

$baseWidth: 100%;
$baseBottom: 45rem;
.portrait {
    .game {
        // position: absolute;
        
        .game-bg{
            width: $baseWidth;
            position: absolute;
            // left: 24rem;
            bottom: $baseBottom;
        }
        .game-frame{
                width: $baseWidth;
            position: absolute;
            // left: 23rem;
            bottom: 48rem;
            z-index: 5;


        }

        .border-frame {
            height: 0.8rem;
            background: var(--hole-container-color);
            bottom: 54rem;
        }

       
    }
}